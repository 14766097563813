<template>
    <!-- <div class="winelist">
        <BrandList v-on:clickBrandBrand="clickBrandBrand" :activeName="brand_id"></BrandList>
        <div>
            <div class="branditem">
                <div class="branditem_hd">
                    <div class="picbg">
                        <VanImage width="100%" height="auto" lazy-load :src="require('@assets/images/brand/brandtitbg.png')" />
                    </div>
                    <div class="pic">
                        <VanImage width="100%" height="auto" lazy-load :src="JudgeListArray.logo" />
                    </div>
                    <div class="goodslist">
                        <div class="dpic" v-for="(item, index) in JudgeListArray.info" :key="index" @click="goPages('/Tasting/wine/info?id=' + item.Product_info.id)">
                            <VanImage width="100%" height="100%" lazy-load fit="cover" :src="item.Product_info.wcj_images" />
                        </div>
                    </div>
                </div>
                <div class="branditem_bd" v-for="(item, index) in JudgeListArray.info" :key="index">
                    <div class="hd" @click="goPages('/Tasting/wine/info?id=' + item.Product_info.id)">
                        <div class="tit">{{item.Product_info.store_name}}</div>
                        <div class="htit">
                            <div class="desc Ellipses1">
                                {{item.Product_info.countries.country}} | {{item.Product_info.countries.brand_name}} | {{item.Product_info.winelever}}
                                <span v-for="(sonitem, index) in item.Product_info.grape" :key="sonindex"> | {{sonitem.brand_name}}</span>
                            </div>
                            <div class="Rate">
                                <span class="Rate_num">{{item.judge_list.score}}</span>
                                <Rate v-model="item.judge_list.score2" readonly allow-half :size="25" color="#ec652b" void-icon="star" void-color="#eee"/>
                            </div>
                            <div class="tiao Ellipses1">{{item.judge_list.count}}条</div>
                        </div>
                        <div class="commentnav">
                            <a href="javascript:void(0);" v-for="(sonitem, index) in item.scene" :key="sonindex">{{sonitem.brand_name}}{{sonitem.num}}</a>
                        </div>
                    </div>
                    <div class="bd">
                        <div class="comment_item" v-for="(sonitem, index) in item.judge_list.list" :key="sonindex" @click="goPages('/Tasting/wine/commentinfo?id=' + sonitem.id)">
                            <div class="pic">
                                <VanImage width="100%" height="100%" lazy-load fit="cover" :src="sonitem.simage" />
                            </div>
                            <div class="comment_tit Ellipses2">
                                {{sonitem.content}}
                            </div>
                            <div class="nickname acea-row row-between">
                                <div class="namename font-color Ellipses1">{{sonitem.nickname}}</div>
                                <div>{{sonitem.rank_name}}</div>
                            </div>
                            <div class="timecity acea-row row-between">
                                <div>{{sonitem.time}}</div>
                                <div class="city Ellipses1">{{sonitem.city}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Judge></Judge>
    </div> -->
    <div class="winelist">
        <!-- <BrandList v-on:clickBrandBrand="clickBrandBrand" :activeName="brand_id"></BrandList> -->
        <div class="brand">
            <TitleList v-on:clickBrandBrand="clickBrandBrand" :activeName="brand_id" ></titleList>
        </div>
        <div>
            <div class="branditem">
                <div class="branditem_hd">
                    <div class="picbg">
                        <VanImage width="100%" height="auto" lazy-load :src="require('@assets/images/brand/brandtitbg.png')" />
                    </div>
                    <div class="pic">
                        <VanImage width="100%" height="auto" lazy-load :src="ProducePic.logo" />
                    </div>
                    <div class="goodslist">
                        <div class="dpic" v-for="(item, index) in ProducePic.product" :key="index" @click="goPages('/Tasting/wine/info?id=' + item.id)">
                            <VanImage width="100%" height="100%" lazy-load fit="cover" :src="item.wcj_images" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="branditem_bd">
                <!-- <div class="hd" v-if="ProducePic">
                    <div class="tit">{{ProducePic.brand_name}}</div>
                    <div class="htit">
                        <div class="Rate">
                            <span class="Rate_num">{{listscore}}</span>
                            <Rate v-model="starscore" readonly allow-half :size="25" color="#ec652b" void-icon="star" void-color="#eee"/>
                        </div>
                        <div class="tiao Ellipses1">{{listcount}}条评论</div>
                    </div>
                </div> -->
            </div>
        </div>
        <!--杯酒论人生-->
        <!-- <div class="comment" style="margin-top:0.2rem">
            <div class="tit">
                <h1>杯酒论人生</h1>
                <div class="judge-img"  @click="goPages('/Tasting/wine/Release')">
                    <img  width="100%" src="@assets/images/judgeBit.png" />
                </div>
            </div>
            <div class="con">
                <div class="comment_item" v-for="(item, index) in list" :key="item" @click="goPages('/Tasting/wine/commentinfo?id=' + item.id)">
                    <div class="hd">
                        <div class="userpic">
                            <VanImage width="100%" height="100%" fit="cover" lazy-load :src="item.avatar" />
                        </div>
                        <div class="rgcon">
                            <div class="rghd acea-row row-between-wrapper">
                                <div>
                                    <div class="name Ellipses1">{{item.nickname}}</div>
                                    <div class="grade">
                                        <span class="bg-color">{{item.rank_name}}</span>
                                        <div class="zhi">
                                            <div>
                                                <VanImage width="0.4rem" height="0.4rem" lazy-load :src="require('@assets/images/grade.png')" />
                                            </div>
                                        </div>
                                        <span class="font-color">酒量值：{{item.exppr}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="add font-color border-color">
                            <Icon name="plus">关注</Icon>
                        </div>
                       
                    </div>
                    <div class="bd">
                        <div class="rgcon">
                            <div class="goodsname">{{item.product_name}}</div>
                            <div class="pingfen">
                                <Rate v-model="item.score" readonly allow-half :size="25" color="#b34550" void-icon="star" void-color="#eee" />
                            </div>
                            <div class="chosen Ellipses3">
                                <span class="font-color border-color" v-if="item.selected==1">精选</span>
                                {{item.content}}
                            </div>
                            <div class="bpic">
                                <div class="bpic_dd" :class="'bpic_dd' + item.images.length" v-for="(sonitem, sonindex) in item.simages" :key="sonitem" @click.stop="bindImagePreview(index, sonindex)">
                                    <VanImage width="100%" height="1.6rem" lazy-load fit="cover" :src="sonitem" />
                                </div>
                            </div>
                            <div class="rgfd acea-row row-between-wrapper">
                                <div>
                                    <span style="display: inline-block;float: left;line-height: 0.4rem;margin-right: 0.1rem;">{{item.time}} </span>
                                    <span class="Ellipses1" style="display: inline-block;line-height: 0.4rem;float: left;width: 1.6rem;">{{item.city}}</span>
                                </div>
                                <div class="rgfdicon">
                                    <Icon name="like-o">{{item.collect_num}}</Icon>
                                    <Icon name="chat-o">{{item.comment_num}}</Icon>
                                    <Icon name="contact">{{item.comment_ren_num}}</Icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="Loading" v-if="loading">
                    <Loading size="24px">加载中...</Loading>
                </div>
                <div class="Loading" v-if="finished">没有更多了~</div>
            </div>
        </div> -->
        <div class="comment">
            <div class="tit">
                <h1>杯酒论人生</h1>
                <div class="judge-img"  @click="goPages('/Tasting/wine/Release')">
                    <img  width="100%" src="@assets/images/judgeBit.png" />
                </div>
            </div>
            <div class="con">
                <div class="comment_item" v-for="(item, index) in list" :key="item" @click="goPages('/Tasting/wine/commentinfo?id=' + item.id)">
                    <div class="bd">
                        <div class="rgcon">
                            <div class="chosen Ellipses3">
                                <span class="font-color border-color" v-if="item.selected==1">精选</span>
                                {{item.content}}
                            </div>
                            <div class="bpic">
                                <div class="bpic_dd" :class="'bpic_dd' + item.images.length" v-for="(sonitem, sonindex) in item.simages" :key="sonitem" @click.stop="bindImagePreview(index, sonindex)">
                                    <VanImage width="100%" height="1.6rem" lazy-load fit="cover" :src="sonitem" />
                                </div>
                            </div>
                            <div class="hd">
                            <div class="userpic">
                                <VanImage width="100%" height="100%" fit="cover" lazy-load :src="item.avatar" />
                            </div>
                            <div class="rgcon">
                                <div class="rghd acea-row row-between-wrapper fiex-row">
                                    <div class="lf">
                                        <div>
                                            <div class="name Ellipses1">{{item.nickname}}</div>
                                            <div class="goodsname Ellipses1">
                                                {{item.product_name}}
                                            </div>
                                            <div class="time-city">
                                                <span style="display: inline-block;float: left;line-height: 0.4rem;margin-right: 0.1rem;">{{item.time}} </span>
                                                <span class="Ellipses1" style="display: inline-block;line-height: 0.4rem;float: left;">{{item.city}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="ri">
                                        <div class="grade" style="    top: 0;right: 0.2rem;font-size: 0.2rem;">
                                            <span class="bg-color" style="padding: 0 0.1rem;font-size: 0.15rem;">{{item.rank_name}}</span>
                                            <div class="zhi">
                                                <div>
                                                    <VanImage style="margin-top: 0.038rem;" width="0.3rem" height="0.3rem" lazy-load :src="require('@assets/images/grade.png')" />
                                                </div>
                                            </div>
                                            <span class="font-color" style="padding: 0 0.1rem;">酒量值：{{item.exppr}}</span>
                                        </div>
                                        <div class="pingfen">
                                            <Rate v-model="item.score/2" readonly allow-half :size="25" color="#b34550" void-icon="star" void-color="#eee" />
                                        </div>
                                        <div class="rgfdicon">
                                            <Icon name="like-o">{{item.collect_num}}</Icon>
                                            <Icon name="chat-o">{{item.comment_num}}</Icon>
                                            <Icon name="contact">{{item.comment_ren_num}}</Icon>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="rgfd acea-row row-between-wrapper">
                            
                        </div>
                    </div>
                </div>
            </div>
                <div class="Loading" v-if="loading">
                    <Loading size="24px">加载中...</Loading>
                </div>
                <div class="Loading" v-if="finished">没有更多了~</div>
            </div>
        </div>
        <Backtop></Backtop>
        <Judge></Judge>
    </div>
</template>
<script>
import { getBrand, getBrandList, JudgeListArray,BrandProduce,JudgeList } from "@api/tasting";
import { Toast, Tab, Tabs, Rate, Empty ,Icon} from "vant";
import { Image as VanImage } from 'vant';
import BrandList from "./../components/brandList";
import TitleList from "./../components/titleList";
import Footer from "./../components/Footer";
import Judge from "../components/Judge";
import Backtop from "./../components/Backtop";
export default {
    name: "winelist",
    data: function() {
        return {
            brand_id: '',
            brandList: [],
            brandList2: [],
            JudgeListArray: '',
            ProducePic:[],

            page: 1,
            limit: 5,
            category_id: '',
            loading: false,
            finished: false,
            list:[],
            listcount:0,
            listscore:10,
            starscore:5,
        };
    },
    components: {
        BrandList, VanImage, Tab, Tabs, Rate, Footer, Empty ,Judge ,Backtop,Toast
        ,Icon,TitleList
    },
    created: function() {
        this.brand_id=Number(this.$route.query.brand_id);
        // console.log(this.brand_id)
    },
    mounted: function() {
        //this.getJudgeListArray();
        this.BrandProduce();
        this.getJudgeList();
        var that=this;
            window.addEventListener("scroll", function(event) {
                var scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
                if(document.documentElement.scrollHeight == document.documentElement.clientHeight + scrollTop ) {
                    that.Loadmore();
                }
            });
    },
    methods: {
        Loadmore: function() {
                if(this.loading) return;
                if(this.finished) return;
                this.getJudgeList();
        },
        getJudgeList: function() {
            this.loading = true;
            JudgeList({
                brand_id: this.brand_id,
                page: this.page,
                limit: this.limit,
                type: 1,
                category_id: this.category_id,
            }).then(res => {
                this.listcount = res.data.count;
                this.listscore = res.data.score;
                this.starscore = res.data.score/2;
                var listdata = res.data.list;
                listdata.forEach((item)=>{
                    this.list.push(item);
                })
                this.loading = false;
                this.page++;
                if(listdata.length<5){
                    this.finished = true;
                }
            })
            .catch(err => {
                this.loading = false;
                Toast.fail({
                    message: err.msg,
                    forbidClick: true,
                });
            });
        },
        getJudge: function() {
            Toast.loading({
                duration: 0,
                message: '加载中...',
                forbidClick: true,
            });
            JudgeList({
                brand_id: this.brand_id,
                page: 1,
                limit: 5,
                type: 1,
                category_id: this.category_id,
            }).then(res => {
                Toast.clear();
                this.$set(this,'list',res.data.list);
                this.$set(this,'listcount',res.data.count);
                this.$set(this,'listscore',res.data.score);
                this.$set(this,'starscore',res.data.score/2);
                this.loading = false;
                this.page++;
                if(res.data.list.length<5){
                    this.finished = true;
                }
            })
            .catch(err => {
                this.loading = false;
                console.log(err);
                Toast.fail({
                    message: err.msg,
                    forbidClick: true,
                });
            });
        },
        BrandProduce: function() {
            console.log(this.brand_id);
            BrandProduce({
                id:this.brand_id,
            }).then(res => {
                this.ProducePic=res.data;
            })
        },
        goPages: function(url) {
            this.$router.push({ path: url });
        },
        clickBrandBrand: function (id) {
            this.brand_id=id;
            this.BrandProduce();
            this.getJudge();
            //this.getJudgeListArray();
            this.$router.push({ path: '/Tasting/wine/list?brand_id=' + id });
        },
        // getJudgeListArray: function() {
        //     Toast.loading({
        //         duration: 0,
        //         message: '加载中...',
        //         forbidClick: true,
        //     });
        //     JudgeListArray({
        //         brand_id: this.brand_id,
        //     }).then(res => {
        //         Toast.clear();
        //         this.JudgeListArray=res.data.data;
        //         this.JudgeListArray.info.forEach((item, index)=>{
        //             item.judge_list.score2=item.judge_list.score/2;
        //         })
        //     })
        //     .catch(err => {
        //         Toast.fail({
        //             message: err.msg,
        //             forbidClick: true,
        //         });
        //     });
        // },
    }
};
</script>
<style scoped lang="scss">
.brand{
    width: 6.5rem;
    background: #f4f4f4;
    position: fixed;
    z-index: 1100;
    top:0.02rem
}
::v-deep{
    .van-icon-star{font-size: 0.32rem!important;}
    .van-rate__item{padding-right: 0.05rem;}
}
::v-deep {
    .brandList{
        .van-tab{padding:0.3rem 0.1rem;}
        .van-tabs__wrap {
            height: 1.2rem;
        }
        .van-tabs__line{background:none;}
        .van-tabs__line::before{
            background:#f4f4f4;
            border-left: 1px solid #b6b6b6;
            border-top: 1px solid #b6b6b6;
            width: 0.2rem;
            height: 0.2rem;
            display: block;
            content: '';
            margin: 0 auto;transform: rotate(45deg);
        }
    }
}
.brandList{
    border-bottom:1px solid #eee;
    .brandpic{
        border: 1px solid #4c4744;border-radius: 0.1rem;overflow: hidden;height: 0.7rem;width: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        img{width: auto;height: 100%;}
    }
}
.branditem{
    background:#fff;overflow: hidden;/*margin-top: 0.2rem;*/border-top: 1px solid #eee;/*padding-top:0.05rem;*/
    .branditem_hd{
        height:1.2rem;background:#f4f4f4;margin-bottom: 0.8rem;position:relative;
        .pic{position:absolute;left: 0.2rem;width:1.82rem;height:1.6rem;border:1px solid #e8e8e8;overflow:hidden;border-radius:0.1rem;z-index:2;}
        .goodslist{
            position:absolute;right: 0;overflow-x: scroll;max-width:5.4rem;white-space: nowrap;z-index:2;
            .dpic{margin: 0.2rem 0.2rem 0;width: 0.46rem;height:1.5rem;display: inline-block;}
        }
        .picbg{position:absolute;left: 1.84rem;right: 0;z-index:1;}
    }
    .branditem_bd{
        position:relative;border-bottom: 1px solid #eee;
        .hd{
            padding: 0.2rem 0.2rem 0;
            .tit{font-size: 0.28rem;}
            .htit{
                font-size: 0.24rem;color:#999;line-height: 0.4rem;overflow:hidden;
                .desc{float:left;max-width: 3rem;}
                .Rate{
                    float:left;display: flex;
                    .Rate_num{color:#ec652b;font-size: 0.36rem;margin-right:0.05rem;}
                    .van-rate{padding-top:0.04rem;}
                }
                .tiao{float:left;color:#333;margin-left: 0.05rem;}
            }
            .commentnav{
                font-size: 0.2rem;overflow:hidden;margin: 0.1rem -0.1rem 0;
                a{
                    display: inline-block;float: left;margin: 0.05rem;border: 1px solid #eee;line-height: 0.5rem;padding: 0 0.1rem;border-radius: 0.1rem;
                }
                
            }
        }
        .bd{
            overflow-x: scroll;white-space: nowrap;padding: 0 0 0.1rem 0.1rem;
            .comment_item{
                width: 2.3rem;display: inline-block;margin: 0.1rem;font-size: 0.2rem;overflow: hidden;
                .pic{height:1.6rem;background:#f5f5f5;border-radius: 0.12rem;overflow: hidden;}
                .comment_tit{width:100%;display: block;line-height: 0.32rem;text-align: left;padding-top: 0.1rem;border-bottom: 1px dashed #949494;}
                .nickname{
                    color:#333;display:flex;padding-top:0.1rem;line-height: 0.3rem;
                    .namename{max-width:1rem;}
                }
                .timecity{
                    color:#999;line-height: 0.5rem;
                    .city{max-width:1rem;}
                }
            }
                
        }
    }
}

//调整
.Loading{text-align: center;padding:0.2rem;font-size:0.28rem;color:#999;}
.branditem_bd{
        position:relative;padding-bottom: 0.2rem;
        .dpic{
            width: 0.8rem;float:left;margin: 0 0.4rem;
        }
        .hd{
            padding: 0.2rem 0.2rem 0 1.7rem;
            .tit{font-size: 0.28rem;}
            .htit{
                font-size: 0.24rem;color:#999;line-height: 0.4rem;overflow:hidden;
                .desc{float:left;}
                .Rate{
                    float:left;display: flex;
                    .Rate_num{color:#ec652b;font-size: 0.36rem;margin-right:0.05rem;}
                    .van-rate{padding-top:0.04rem;}
                }
                .tiao{float:left;color:#333;margin-left: 0.05rem;}
            }
            .commentnav{
                font-size: 0.2rem;overflow:hidden;margin: 0.1rem -0.1rem 0;
                a{
                    display: inline-block;float: left;margin: 0.05rem;border: 1px solid #eee;line-height: 0.5rem;padding: 0 0.1rem;border-radius: 0.1rem;
                }
                
            }
        }
    }
.hd{
    img{width: 100%;}
}
.comment{
    margin-top: 0.4rem;background:#fff;
    //border-top-left-radius: 0.2rem;border-top-right-radius: 0.2rem;
    .tit{
        border-bottom: 1px solid #eee;padding: 0.2rem 0;position:relative;
        h1{text-align: center;font-size: 0.36rem;}
        .judge-img{
            margin-top:0.2rem;
        }
        .ed{position:absolute;top:0;right:0.2rem;line-height:0.9rem;}
        .commentnav{
            font-size: 0.24rem;overflow:hidden;margin-top: 0.1rem;
            a{
                display: inline-block;float: left;margin: 0.1rem;border: 1px solid #eee;line-height: 0.5rem;padding: 0 0.1rem;border-radius: 0.1rem;
            }
            
        }
    }
    .comment_item{
        position: relative;
        border-bottom: 1px solid #eee;padding: 0.2rem;font-size: 0.24rem;
        .hd{
            position:relative;
            .userpic{float:left;width:1rem;height:1rem;display:block;overflow:hidden;border-radius: 100%;}
            .rgcon{
                padding-left: 1.15rem;color: #999;
                height: 1.2rem;
                .fiex-row{
                    display: flex;
                }
                .lf{
                    width: 45%;
                    .time-city{
                        padding-top: 0.1rem;
                    }
                }
                .ri{
                    
                }
                .rghd{
                    height:1rem;
                    .name{max-width: 3rem;font-size: 0.24rem;color:#000;margin-top: 0.1rem;}
                    .grade{
                        margin-top: 0.05rem;
                        span{color:#fff;line-height: 0.4rem;display:block;float:left;padding: 0 0.05rem;border-radius:0.08rem;}
                        span:first-child{padding: 0 0.2rem;}
                        .zhi{
                            float:left;text-align: right;margin: 0 0.05rem 0 0.2rem;
                            .van-image{margin-left:-0.12rem;}
                        }
                    }
                    .zhi{text-align: right;}
                }
                .goodsname{font-size: 0.24rem;margin-top: 0.1rem;}
                .pingfen{padding: 0.1rem 0 ;}
                .label{
                    overflow:hidden;
                    span{display:block;float:left;margin-right:0.2rem;}
                }
                .chosen{
                    color:#333;line-height:0.4rem;padding: 0.1rem 0;font-size:0.28rem;
                    span{padding: 0.02rem 0.1rem;border-radius: 0.08rem;font-size:0.24rem;}
                }
                .bpic{
                    overflow:hidden;
                    .bpic_dd{
                        width:33.333%;padding:0.05rem;float:left;
                        .van-image{}
                    }
                    .bpic_dd1{width:100%!important;}
                    .bpic_dd4{width:50%!important;}
                }
                .sharegoods{
                    background:#f3f3f5;padding: 0.1rem;overflow:hidden;
                    .sharegoods_pic{float:left;width: 1rem;height:1rem;}
                    .sharegoods_con{
                        padding-left: 1.2rem;padding-top:0.1rem;
                        .dd{line-height:0.3rem;color:#333;}
                    }
                }
                .rgfd{
                    padding-top: 0.1rem;
                    .rgfdicon{
                        .van-icon{float:left;margin-left: 0.15rem;}
                        .van-icon::before{font-size: 0.28rem;margin-right: 0.05rem;float:left;}
                    }
                }
            }
            .add{
                position: absolute;top:0.24rem;right:0;display:block;font-size:0.24rem;padding:0.1rem;border-radius:0.1rem;border: 1px solid #999;color:#999;
                i{display: flex;}
                i::before{font-size:0.28rem;margin-right:0.05rem;}
            }
        }
        .bd{
            overflow:hidden;
            .userpic{float:left;width:1rem;height:1rem;display:block;overflow:hidden;border-radius: 100%;}
            .rgcon{
                //padding-left: 1.2rem;
                color: #999;
                .rghd{
                    height:1rem;
                    .name{max-width: 3rem;font-size: 0.28rem;color:#000;}
                    .grade{
                        margin-top: 0.05rem;
                        span{color:#fff;line-height: 0.4rem;display:block;float:left;padding: 0 0.3rem;border-radius:0.08rem;}
                    }
                    .zhi{text-align: right;}
                }
                // .goodsname{font-size: 0.28rem;padding-left: 1.2rem;

                // }
                .pingfen{
                    //padding: 0.1rem 0;
                    //margin-top: 0.3rem;float:right;margin-right: 0.2rem;
                    text-align: right;
                    }
                .label{
                    overflow:hidden;
                    span{display:block;float:left;margin-right:0.2rem;}
                }
                .chosen{
                    color: #525252;
                    line-height: 0.4rem;
                    padding-top: 0.1rem;
                    font-size: 0.3rem;
                    margin-bottom:0.1rem;
                    span{padding: 0.02rem 0.1rem;border-radius: 0.08rem;}
                }
                .bpic{
                    margin: 0.1rem 0;
                    overflow:hidden;
                    .bpic_dd{
                        width:100%;padding:0.05rem;float:left;
                        .van-image{}
                    }
                    .bpic_dd1{
                        width:100%!important;
                        .van-image{height:5rem!important;}
                    }
                    .bpic_dd3,.bpic_dd5,.bpic_dd6,.bpic_dd7,.bpic_dd8,.bpic_dd9{
                        width:33.33%!important;
                        .van-image{height:2rem!important;}
                    }
                    .bpic_dd4,.bpic_dd2{
                        width:50%!important;
                        .van-image{height:3rem!important;}
                    }
                }
                .sharegoods{
                    background:#f3f3f5;padding: 0.1rem;overflow:hidden;
                    .sharegoods_pic{float:left;width: 1rem;height:1rem;}
                    .sharegoods_con{
                        padding-left: 1.2rem;padding-top:0.1rem;
                        .dd{line-height:0.3rem;color:#333;}
                    }
                }
                .rgfdicon{
                        margin-right: 0.1rem;
                        .van-icon{float:right;margin-left: 0.15rem;}
                        .van-icon::before{font-size: 0.28rem;margin-right: 0.05rem;float:left;}
                    }
                .rgfd{
                    padding-top: 0.2rem;
                    
                }
            }
        }
    }
    .more{text-align: center;line-height:0.8rem;}
}
// .comment{
//     margin-top: 0.4rem;background:#fff;
//     //border-top-left-radius: 0.2rem;border-top-right-radius: 0.2rem;
//     .tit{
//         border-bottom: 1px solid #eee;padding: 0.2rem 0;position:relative;
//         h1{text-align: center;font-size: 0.36rem;}
//         .judge-img{
//             margin-top:0.2rem;
//         }
//         .ed{position:absolute;top:0;right:0.2rem;line-height:0.9rem;}
//         .commentnav{
//             font-size: 0.24rem;overflow:hidden;margin-top: 0.1rem;
//             a{
//                 display: inline-block;float: left;margin: 0.1rem;border: 1px solid #eee;line-height: 0.5rem;padding: 0 0.1rem;border-radius: 0.1rem;
//             }
            
//         }
//     }
//     .comment_item{
//         position: relative;
//         border-bottom: 1px solid #eee;padding: 0.2rem;font-size: 0.24rem;
//         .hd{
//             overflow:hidden;position:relative;
//             .userpic{float:left;width:1rem;height:1rem;display:block;overflow:hidden;border-radius: 100%;}
//             .rgcon{
//                 padding-left: 1.15rem;color: #999;
//                 .rghd{
//                     height:1rem;
//                     .name{max-width: 3rem;font-size: 0.24rem;color:#000;}
//                     .grade{
//                         margin-top: 0.05rem;
//                         span{color:#fff;line-height: 0.4rem;display:block;float:left;padding: 0 0.05rem;border-radius:0.08rem;}
//                         span:first-child{padding: 0 0.2rem;}
//                         .zhi{
//                             float:left;text-align: right;margin: 0 0.05rem 0 0.2rem;
//                             .van-image{margin-left:-0.12rem;}
//                         }
//                     }
//                     .zhi{text-align: right;}
//                 }
//                 .goodsname{font-size: 0.24rem;}
//                 .pingfen{padding: 0.1rem 0;}
//                 .label{
//                     overflow:hidden;
//                     span{display:block;float:left;margin-right:0.2rem;}
//                 }
//                 .chosen{
//                     color:#333;line-height:0.4rem;padding: 0.1rem 0;font-size:0.28rem;
//                     span{padding: 0.02rem 0.1rem;border-radius: 0.08rem;font-size:0.24rem;}
//                 }
//                 .bpic{
//                     overflow:hidden;
//                     .bpic_dd{
//                         width:100%;padding:0.05rem;float:left;
//                         .van-image{}
//                     }
//                     .bpic_dd1{
//                         width:100%!important;
//                         .van-image{height:3.6rem!important;}
//                     }
//                     .bpic_dd3,.bpic_dd5,.bpic_dd6,.bpic_dd7,.bpic_dd8,.bpic_dd9{
//                         width:33.33%!important;
//                         .van-image{}
//                     }
//                     .bpic_dd4,.bpic_dd2{
//                         width:50%!important;
//                         .van-image{height:2rem!important;}
//                     }
//                 }
//                 .sharegoods{
//                     background:#f3f3f5;padding: 0.1rem;overflow:hidden;
//                     .sharegoods_pic{float:left;width: 1rem;height:1rem;}
//                     .sharegoods_con{
//                         padding-left: 1.2rem;padding-top:0.1rem;
//                         .dd{line-height:0.3rem;color:#333;}
//                     }
//                 }
//                 .rgfd{
//                     padding-top: 0.1rem;
//                     .rgfdicon{
//                         .van-icon{float:left;margin-left: 0.15rem;}
//                         .van-icon::before{font-size: 0.28rem;margin-right: 0.05rem;float:left;}
//                     }
//                 }
//             }
//             .add{
//                 position: absolute;top:0.24rem;right:0;display:block;font-size:0.24rem;padding:0.1rem;border-radius:0.1rem;border: 1px solid #999;color:#999;
//                 i{display: flex;}
//                 i::before{font-size:0.28rem;margin-right:0.05rem;}
//             }
//         }
//         .bd{
//             overflow:hidden;
//             .userpic{float:left;width:1rem;height:1rem;display:block;overflow:hidden;border-radius: 100%;}
//             .rgcon{
//                 //padding-left: 1.2rem;
//                 color: #999;
//                 .rghd{
//                     height:1rem;
//                     .name{max-width: 3rem;font-size: 0.28rem;color:#000;}
//                     .grade{
//                         margin-top: 0.05rem;
//                         span{color:#fff;line-height: 0.4rem;display:block;float:left;padding: 0 0.3rem;border-radius:0.08rem;}
//                     }
//                     .zhi{text-align: right;}
//                 }
//                 .goodsname{font-size: 0.28rem;padding-left: 1.2rem;}
//                 .pingfen{padding: 0.1rem 0;padding-left: 1.2rem;}
//                 .label{
//                     overflow:hidden;
//                     span{display:block;float:left;margin-right:0.2rem;}
//                 }
//                 .chosen{
//                     color:#333;line-height:0.4rem;padding: 0.1rem 0;
//                     span{padding: 0.02rem 0.1rem;border-radius: 0.08rem;}
//                 }
//                 .bpic{
//                     overflow:hidden;
//                     .bpic_dd{
//                         width:100%;padding:0.05rem;float:left;
//                         .van-image{}
//                     }
//                     .bpic_dd1{
//                         width:100%!important;
//                         .van-image{height:3.6rem!important;}
//                     }
//                     .bpic_dd3,.bpic_dd5,.bpic_dd6,.bpic_dd7,.bpic_dd8,.bpic_dd9{
//                         width:33.33%!important;
//                         .van-image{}
//                     }
//                     .bpic_dd4,.bpic_dd2{
//                         width:50%!important;
//                         .van-image{height:2rem!important;}
//                     }
//                 }
//                 .sharegoods{
//                     background:#f3f3f5;padding: 0.1rem;overflow:hidden;
//                     .sharegoods_pic{float:left;width: 1rem;height:1rem;}
//                     .sharegoods_con{
//                         padding-left: 1.2rem;padding-top:0.1rem;
//                         .dd{line-height:0.3rem;color:#333;}
//                     }
//                 }
//                 .rgfd{
//                     padding-top: 0.1rem;
//                     .rgfdicon{
//                         .van-icon{float:left;margin-left: 0.15rem;}
//                         .van-icon::before{font-size: 0.28rem;margin-right: 0.05rem;float:left;}
//                     }
//                 }
//             }
//         }
//     }
//     .more{text-align: center;line-height:0.8rem;}
// }
</style>